import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Title from 'components/Title';
import Sidebar from 'components/Course/Sidebar';

import { useCourseData } from 'utils/context';
import { overviewList } from 'data/overview';

function Intro() {
  const { courseData } = useCourseData();
  return (
    <>
      <section className="l-course-intro u-section c-article" id="intro">
        <Title text="課程簡介" align="start" />
        <div className="row gx-5 gy-4 gx-md-3 gx-lg-5">
          <div className="col-12 col-md-6 col-lg-7 d-flex flex-column justify-content-between">
            <p className="l-course__paragraph c-article__paragraph">
              {courseData.introduction}
            </p>
            {courseData.learningPath && (
              <Link
                to={`/learning-path`}
                className="e-btn e-btn--primary e-btn--wmax l-course__action"
              >
                前往學習地圖
              </Link>
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-5">
            <Sidebar />
          </div>
        </div>
      </section>
    </>
  );
}

export default Intro;
