import React from 'react';
import Heading from '../Heading';

import featureImg1 from 'img/winter-camp-2025/feature/feature-1.png';
import featureImg2 from 'img/winter-camp-2025/feature/feature-2.png';
import featureImg3 from 'img/winter-camp-2025/feature/feature-3.png';
import featureImg4 from 'img/winter-camp-2025/feature/feature-4.png';
import featureImg5 from 'img/winter-camp-2025/feature/feature-5.png';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__feature`;
  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section overflow-hidden`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <Heading
              LAYOUT_CLASS={LAYOUT_CLASS}
              title="ThousandAI的特色"
              subtitle="ThousandAI的特色是我們對家長們最大的保證！"
            />
            <div className={`${sectionClass}__row`}>
              <div className="row justify-content-center w-100 g-0">
                <div className="col-6 d-none d-lg-block">
                  <div className="row justify-content-end w-100">
                    <div
                      className="col-10 col-xl-8 col-xxl-6 position-relative"
                      data-number="1"
                    >
                      <div className={`${sectionClass}__block`} data-number="1">
                        <img
                          src={featureImg1}
                          alt="台大專業師資"
                          className="w-100 h-100 object-fit-contain"
                        />
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--1`}
                        ></div>
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--2`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="position-relative">
                    <div className="row justify-content-center justify-content-lg-start w-100">
                      <div className="col-10 col-xl-8 col-xxl-6">
                        <div
                          className={`${sectionClass}__block vstack gap-4`}
                          data-number="2"
                        >
                          <h6 className={`${sectionClass}__title`}>
                            台大專業師資
                          </h6>
                          <p className={`${sectionClass}__content`}>
                            我們的師資皆來自台大電資學院碩博士生，專業研究AI相關內容，且都擁有豐富的教學經驗，家長可以安心交給我們！
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__bg`}
                      data-number="1"
                    ></div>
                    <div className={`${sectionClass}__number`} data-number="1">
                      01
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${sectionClass}__row`}>
              <div className="row justify-content-center w-100 g-0">
                <div className="col-12 col-lg-6">
                  <div className="position-relative">
                    <div className="row justify-content-center justify-content-lg-end w-100">
                      <div className="col-10 col-xl-8 col-xxl-6">
                        <div
                          className={`${sectionClass}__block vstack gap-4`}
                          data-number="2"
                        >
                          <h6 className={`${sectionClass}__title`}>
                            課後的作業輔導
                          </h6>
                          <p className={`${sectionClass}__content`}>
                            回家作業有助於內話學生所學知識，老師會在學生需要時提供必要的幫助，並確保學生能在時間內完成作業，讓每堂課的學習達到效果最大化
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__bg`}
                      data-number="2"
                    ></div>
                    <div
                      className={`${sectionClass}__number ${sectionClass}__number--2`}
                    >
                      02
                    </div>
                  </div>
                </div>
                <div className="col-6 d-none d-lg-block">
                  <div className="row justify-content-start w-100 position-relative">
                    <div className="col-10 col-xl-8 col-xxl-6 position-relative">
                      <div className={`${sectionClass}__block`} data-number="4">
                        <img
                          src={featureImg2}
                          alt="課後的作業輔導"
                          className="w-100 h-100 object-fit-contain"
                        />
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--3`}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__deco ${sectionClass}__deco--4`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${sectionClass}__row`}>
              <div className="row justify-content-center w-100 g-0">
                <div className="col-6 d-none d-lg-block">
                  <div className="row justify-content-end w-100">
                    <div
                      className="col-10 col-xl-8 col-xxl-6 position-relative"
                      data-number="3"
                    >
                      <div className={`${sectionClass}__block`} data-number="3">
                        <img
                          src={featureImg3}
                          alt="discord專屬頻道"
                          className="w-100 h-100 object-fit-contain"
                        />
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--5`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="position-relative">
                    <div className="row justify-content-center justify-content-lg-start w-100">
                      <div className="col-10 col-xl-8 col-xxl-6">
                        <div
                          className={`${sectionClass}__block vstack gap-4`}
                          data-number="3"
                        >
                          <h6 className={`${sectionClass}__title`}>
                            discord專屬頻道
                          </h6>
                          <p className={`${sectionClass}__content`}>
                            ThousandAI使用discord作為與學生溝通討論的媒介，讓學習變得更加有趣。專屬的頻道能讓學生隨時提問，互相討論，讓學習零時差！
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__bg`}
                      data-number="3"
                    ></div>
                    <div className={`${sectionClass}__number`} data-number="3">
                      03
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${sectionClass}__row`}>
              <div className="row justify-content-center w-100 g-0">
                <div className="col-12 col-lg-6">
                  <div className="position-relative">
                    <div className="row justify-content-center justify-content-lg-end w-100">
                      <div className="col-10 col-xl-8 col-xxl-6">
                        <div
                          className={`${sectionClass}__block vstack gap-4`}
                          data-number="4"
                        >
                          <h6 className={`${sectionClass}__title`}>
                            多樣性程式課程
                          </h6>
                          <p className={`${sectionClass}__content`}>
                            除了基本的檢定班課程，我們還有開設Unity遊戲設計課程，讓學生選擇不設限！
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__bg`}
                      data-number="4"
                    ></div>
                    <div
                      className={`${sectionClass}__number ${sectionClass}__number--4`}
                    >
                      04
                    </div>
                  </div>
                </div>
                <div className="col-6 d-none d-lg-block">
                  <div className="row justify-content-start w-100 position-relative">
                    <div className="col-10 col-xl-8 col-xxl-6 position-relative">
                      <div className={`${sectionClass}__block`} data-number="4">
                        <img
                          src={featureImg4}
                          alt="多樣性程式課程"
                          className="w-100 h-100 object-fit-contain"
                        />
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--3`}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__deco ${sectionClass}__deco--7`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${sectionClass}__row`}>
              <div className="row justify-content-center w-100 g-0">
                <div className="col-6 d-none d-lg-block">
                  <div className="row justify-content-end w-100">
                    <div
                      className="col-10 col-xl-8 col-xxl-6 position-relative"
                      data-number="5"
                    >
                      <div className={`${sectionClass}__block`} data-number="5">
                        <img
                          src={featureImg5}
                          alt="實體、線上、數位課程任你選！"
                          className="w-100 h-100 object-fit-contain"
                        />
                        <div
                          className={`${sectionClass}__deco ${sectionClass}__deco--5`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="position-relative">
                    <div className="row justify-content-center justify-content-lg-start w-100">
                      <div className="col-10 col-xl-8 col-xxl-6">
                        <div
                          className={`${sectionClass}__block vstack gap-4`}
                          data-number="5"
                        >
                          <h6 className={`${sectionClass}__title`}>
                            實體、線上、數位課程任你選！
                          </h6>
                          <p className={`${sectionClass}__content`}>
                            除了基本的實體班，ThousandAI同時擁有線上課及數位影音課程，讓地理位置不方便或是不受限制影片教學的學生有更多選擇！
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${sectionClass}__bg`}
                      data-number="5"
                    ></div>
                    <div className={`${sectionClass}__number`} data-number="3">
                      05
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
