import React from 'react';

const Heading = ({ LAYOUT_CLASS, title, subtitle }) => {
  return (
    <>
      <div className={`${LAYOUT_CLASS}__heading vstack gap-4`}>
        <div className={`${LAYOUT_CLASS}__heading__title`}>
          <h1 className={`${LAYOUT_CLASS}__heading__title__text text-nowrap `}>
            {title}
          </h1>
          <div className={`${LAYOUT_CLASS}__heading__title__deco`}></div>
        </div>
        {subtitle && (
          <h2 className={`${LAYOUT_CLASS}__heading__subtitle text-center`}>
            {subtitle}
          </h2>
        )}
      </div>
    </>
  );
};

export default Heading;
