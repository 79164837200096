import React from 'react';

import Banner from 'components/Banner';

const PageTempelate = (props) => {
  const { title, subtitle, content, bannerStyle, xlCol = 8, keyVisual } = props;
  const containerClass = subtitle.replace(' ', '-').toLowerCase();
  return (
    <>
      <Banner title={title} subtitle={subtitle} bannerStyle={bannerStyle} />
      <div className={`l-${containerClass}`}>
        {keyVisual}
        <div className="row gx-0 justify-content-center w-100 overflow-hidden position-relative">
          <div className={`col-10 col-xl-${xlCol}`}>
            <article className="u-page u-page--placeholder c-article">
              {content}
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTempelate;
