import { courseList } from 'data/winter-camp-2025';
import React from 'react';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__key-visual`;
  return (
    <>
      <section className={`${sectionClass} ${LAYOUT_CLASS}__section u-section`}>
        <div className={`${sectionClass}__heading vstack gap-3 gap-lg-5`}>
          <h1 className={`${sectionClass}__title`}>
            寒假冬令營
            <span className={`${sectionClass}__title-deco`}>ThousandAI</span>
          </h1>
          <h2 className={`${sectionClass}__subtitle d-none d-lg-block`}>
            Python x AI、APCS系列、Unity Game Design
          </h2>
          <div className={`${sectionClass}__text`}>
            2025/01/18 - 2025/02/10
            <div className={`${sectionClass}__text-deco`}></div>
          </div>
          <div className="vstack gap-3 flex-grow-0 d-lg-none">
            {courseList.map((o) => (
              <div className="hstack gap-3 justify-content-center align-items-center">
                <div className={`${sectionClass}__icon`}>
                  <img
                    src={o.icon}
                    alt=""
                    className="w-100 h-100 object-fit-contain"
                  />
                </div>
                <h2 className={`${sectionClass}__subtitle`}>{o.title}</h2>
              </div>
            ))}
          </div>
        </div>
        <div className={`${sectionClass}__fg ${sectionClass}__fg--left`}></div>
        <div className={`${sectionClass}__fg ${sectionClass}__fg--right`}></div>
        <div className={`${sectionClass}__bg`}></div>
        <div
          className={`${sectionClass}__deco ${sectionClass}__deco--top`}
        ></div>
        <div
          className={`${sectionClass}__deco ${LAYOUT_CLASS}__key-visual__deco--bottom`}
        ></div>
      </section>
    </>
  );
};

export default Main;
