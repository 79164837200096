import React from 'react';
import Heading from '../Heading';
import { courseList } from 'data/winter-camp-2025';
import { Link } from 'react-router-dom';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__course`;
  return (
    <>
      <section className={`${sectionClass} ${LAYOUT_CLASS}__section u-section`}>
        <div className="row justify-content-center g-0">
          <div className="col-10">
            <Heading
              LAYOUT_CLASS={LAYOUT_CLASS}
              title="冬令營課程介紹"
              subtitle="多元化課程讓學生擁有更多選擇！"
            />
            <div className="row justify-content-center g-5">
              {courseList.map((o) => (
                <div key={o.id} className="col-12 col-lg-4 col-xxl-3">
                  <div className="vstack gap-2 gap-lg-4 justify-content-center align-items-center">
                    <div className={`${sectionClass}__block`}>
                      <div className={`${sectionClass}__icon`}>
                        <img
                          src={o.icon}
                          alt=""
                          className="w-100 h-100 object-fit-contain"
                        />
                      </div>
                    </div>
                    <h6 className={`${sectionClass}__title`}>{o.title}</h6>
                    <p className={`${sectionClass}__content`}>{o.content}</p>
                    <Link
                      to={o.link}
                      className={`${sectionClass}__action  e-btn e-btn--primary e-btn--wmax`}
                    >
                      了解更多
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
