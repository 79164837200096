import iconImg1 from 'img/course/icon/python.svg';
import iconImg2 from 'img/course/icon/apcs.png';
import iconImg3 from 'img/course/icon/unity.svg';

export const courseList = [
  {
    id: 'python',
    icon: iconImg1,
    title: 'Python x AI',
    content: `最流行的程式語言，最新潮的 AI 技術，以及扎實的系統化課程和完善的作業輔導，這門課是零基礎入門的最佳選擇。`,
    link: '/course/pycs1',
  },
  {
    id: 'apcs',
    icon: iconImg2,
    title: 'APCS系列',
    content: `最詳細的 C/C++ 語言課程，培養扎實的程式邏輯，建立縝密的解題思維，8堂課即可參加APCS檢定。`,
    link: '/course/apcs1',
  },
  {
    id: 'unity',
    icon: iconImg3,
    title: 'Unity遊戲班',
    content: `探索遊戲開發的奧秘，學習如何打造遊戲並激發創意。通過掌握Unity技術，將開發兩款遊戲，踏上成為下一個遊戲創造者的旅程！`,
    link: 'https://www.surveycake.com/s/Z7eGG',
  },
];

// 2025冬季班資訊 - 開課時間
export const scheduleList = [
  {
    id: 1,
    title: 'Unity 遊戲設計 2D',
    subtitle: '',
    date: '2025/1/21-2025/1/24',
    weekday: '星期二三四五',
    time: '09:00-12:00',
    totalTime: '12小時 (4堂)',
    location: '民權西路站',
    tutor: 'Thousand',
    href: 'https://www.surveycake.com/s/mgwBq',
    price: `$8,500`,
    earlyPrice: '早鳥優惠折$1,000',
    groupPrice: '團報優惠折$1,500',
    placement: 'top',
  },
  {
    id: 2,
    title: 'Unity 遊戲設計 2D',
    subtitle: '',
    date: '2025/2/3-2025/2/7',
    weekday: '星期一二三五',
    time: '09:00-12:00',
    totalTime: '12小時 (4堂)',
    location: '善導寺站',
    tutor: 'Thousand & Steven',
    href: 'https://www.surveycake.com/s/mgwBq',
    price: `$8,500`,
    earlyPrice: '早鳥優惠折$1,000',
    groupPrice: '團報優惠折$1,500',
    placement: 'top',
  },
  {
    id: 3,
    title: 'Python x AI',
    subtitle: '',
    date: '2025/2/3-2025/2/10',
    weekday: '星期一二三四五',
    time: '09:00-12:00 (12:30)',
    totalTime: '20小時 (6堂)',
    location: '善導寺站',
    tutor: 'Thousand & Steven',
    href: 'https://www.surveycake.com/s/NqmNB',
    price: `$13,000`,
    earlyPrice: '早鳥優惠折$1,000',
    groupPrice: '團報優惠折$1,500',
    placement: 'center',
  },
  {
    id: 4,
    title: 'APCS 入門班',
    subtitle: '',
    date: '2025/2/3-2025/2/10',
    weekday: '星期一二三四五',
    time: '13:30-17:30',
    totalTime: '24小時 (6堂)',
    location: '善導寺站',
    tutor: 'Thousand & Steven',
    href: 'https://www.surveycake.com/s/egK2a',
    price: `$15,500`,
    earlyPrice: '早鳥優惠折$1,000',
    groupPrice: '團報優惠折$1,500',
    placement: 'bottom',
  },
  {
    id: 5,
    title: 'APCS 大師班',
    subtitle: '',
    date: '2025/1/18-2025/2/10',
    weekday: '星期二三四五六日',
    time: '09:00-12:00',
    totalTime: '30小時 (10堂)',
    location: '善導寺站',
    tutor: 'Thousand',
    href: 'https://www.surveycake.com/s/4myWZ',
    price: `$18,500`,
    earlyPrice: '早鳥優惠折$1,000',
    groupPrice: '團報優惠折$1,500',
    placement: 'bottom',
  },
];
