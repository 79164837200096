import React from 'react';
import { qaList } from 'data/summer-camp';
import Item from './Item';
import Heading from '../Heading';

const Content = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__qa`;

  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section overflow-hidden`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <Heading LAYOUT_CLASS={LAYOUT_CLASS} title="問答Q&A" subtitle="" />
            <div className="vstack gap-3">
              {qaList.map((v) => {
                return <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />;
              })}
            </div>
          </div>
        </div>
        <div className={`${sectionClass}__deco`}></div>
      </section>
    </>
  );
};

export default Content;
