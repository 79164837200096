import React from 'react';
import Heading from '../Heading';
import { scheduleList } from 'data/winter-camp-2025';
import { Link } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';

const Main = ({ LAYOUT_CLASS }) => {
  const sectionClass = `${LAYOUT_CLASS}__schedule`;
  const { width } = useWindowDimensions();
  const isMobile = width && width < 992;

  const headList = [
    { id: 'course', title: '課程', keys: ['title', 'subtitle'] },
    {
      id: 'time',
      title: '上課時間',
      keys: ['date', 'weekday', 'time', 'totalTime'],
    },
    { id: 'location', title: '地點', keys: ['location'] },
    { id: 'price', title: '金額', keys: ['price', 'earlyPrice', 'groupPrice'] },
    { id: 'tutor', title: '老師', keys: ['tutor'] },
    { id: 'action', title: '', keys: ['action'] },
  ];
  const spaceIdList = ['tutor', 'location'];
  return (
    <>
      <section
        className={`${sectionClass} ${LAYOUT_CLASS}__section u-section overflow-hidden`}
      >
        <div className="row justify-content-center g-0">
          <div className="col-11">
            <Heading LAYOUT_CLASS={LAYOUT_CLASS} title="開課時間" subtitle="" />
            <div className="vstack gap-0 gap-lg-5">
              {['top', 'center', 'bottom'].map((placement) => (
                <div key={placement} className={`${sectionClass}__table`}>
                  <div className="position-relative">
                    <div
                      className={`${sectionClass}__row ${sectionClass}__row--head ${sectionClass}__row--${placement}`}
                    >
                      {headList.map((o) => (
                        <div key={o.id} className={`${sectionClass}__col`}>
                          {o.title}
                        </div>
                      ))}
                    </div>
                    <div
                      className={`${sectionClass}__deco ${sectionClass}__deco--${placement}`}
                    ></div>
                  </div>
                  {scheduleList
                    .filter((o) => o.placement === placement)
                    .map((row, i) => (
                      <div
                        key={row.id}
                        className={`${sectionClass}__row`}
                        data-row={i % 2 !== 0 ? 'odd' : 'even'}
                      >
                        {headList.map((col) =>
                          col.keys.map((key, i) => (
                            <div
                              key={key}
                              className={`${sectionClass}__col`}
                              data-grid={key}
                            >
                              {key !== 'action' ? (
                                `${
                                  isMobile && col.id !== 'course' && i === 0
                                    ? `${col.title}: `
                                    : ''
                                }${row[key]}`
                              ) : (
                                <a
                                  key={col.id}
                                  href={row.href}
                                  className={`${sectionClass}__action ${sectionClass}__action--${placement} e-btn e-btn--primary e-btn--wmax`}
                                >
                                  報名
                                </a>
                              )}
                            </div>
                          ))
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
